import api from '@/components/mixins/baseApi';

export default {
  mixins: [api],

  methods: {
    getBanner(_page, _ts) {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/banner/${_page}${_ts ? `/${_ts}` : ''}`,
      })
        .then((data) => data.results || null)
        .catch(() => {
          console.error(`Error getting banner ${_page}`);
          return Promise.resolve(null);
        });
    },
  },
};
