import api from '@/components/mixins/baseApi';

export default {
  mixins: [api],
  methods: {
    getSpeackersList(page = null) {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/speaker/page/${page ? `${page}/` : ''}`,
      })
        .then((data) => data)
        .catch((error) => {
          Promise.reject(error);
        });
    },
    getSpeackerPersone(id) {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/speaker/${id}`,
      })
        .then((data) => data?.results || {})
        .catch((error) => {
          Promise.reject(error);
        });
    },
  },
};
