<template>
  <div class="questions-list__wrapper">
    <div
      v-if="isQuestionList && !isLoading"
      class="questions-list"
    >
      <div
        v-for="question in questionList"
        :key="question.id"
        class="questions-list__item"
      >
        <question-item :question="question"/>
      </div>
    </div>
    <div
      v-if="!isQuestionList && !isLoading"
      class="noQuestions"
    >
      {{ $t('questionList.no_question') }}
    </div>
      <pre-loader v-if="isLoading"/>
  </div>
</template>
<script>
import lectures from '@/components/mixins/methodsLectures';
import questionItem from './questionItem.vue';

export default {
  name: 'QuestionsList',
  components: { questionItem },
  mixins: [lectures],
  props: ['lecture'],
  data() {
    return {
      questionList: [],
      isLoading: false,
    };
  },
  watch: {
    lectureId() {
      this.getQuestionList();
    },
  },
  computed: {
    lectureId() {
      return this.lecture.id;
    },
    isQuestionList() {
      return this.questionList.length > 0;
    },
  },
  mounted() {
    this.getQuestionList();
  },
  methods: {
    getQuestionList() {
      this.isLoading = true;
      this.getLectureQuestion(this.lectureId)
        .then((question) => {
          this.isLoading = false;
          this.questionList = [...question];
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.questions-list {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 0;

  &__wrapper {
    flex: 1 1 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
  }
  &__item {
    padding: 0 12px;
    p {
      margin: 0;
      &.break-line {
        white-space: break-spaces;
      }
    }
  }
}
.noQuestions {
  padding: 0 16px;
  text-align: center;
  color: #a7a7b3;
}
</style>
