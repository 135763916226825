import axios from 'axios';
// eslint-disable-next-line import/no-unresolved
// import router from 'router';

export default {
  methods: {
    baseAxios(props) {
      return axios({
        headers: this.$store.getters.getToken ? { Authorization: `Bearer ${this.$store.getters.getToken}` } : {},
        method: props.method,
        url: props.url,
        data: props.data || ({}),
      })
        .then(({ data }) => data)
        .catch((error) => {
          if (this.$route.name !== 'loginView') {
            this.$store.commit('setLastUrl', { name: this.$route.name, params: this.$route.params });
          }
          const { results } = error.response.data;
          switch (error.response.status) {
            case 301:
              // TODO продумать структуру ответа на редирект
              if (results.redirect.appname === 'static-page') {
                this.$router.push({ name: 'staticPage', params: { alias: results.redirect.page } });
              }
              break;
            case 401:
              return Promise.resolve({ results: { message: 'Неизвестный App ID' } });
              // break;
            case 403:
              this.$router.push({ name: 'loginView' });
              break;
            case 404:
              this.$router.push({ name: 'page404' });
              break;
            case 418:
              console.error('Не понимаю... Может лучше выпьем чаю?');
              break;
            default:
              return Promise.reject(error);
              // break;
          }
          return null;
        });
    },
  },
};
