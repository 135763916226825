<template>
  <up-card>
    <div class="room-info__wrapper">
      <div class="room-info_photo__wrapper">
        <div
          v-if="roomInfo.invited[0].photo"
          class="room-info_photo"
        >
            <img
              :src="roomInfo.invited[0].photo"
              alt=""
            >
        </div>
        <div
          v-else
          :class="photoAlter"
          class="room-info_photo alter-photo"
        >
          <div class="photo-block">
            {{ firstLetter }}
          </div>
        </div>
      </div>
      <div class="chatroom-info">
        <span class="participant-persone">{{ roomInfo.invited[0].name }}</span>
        <span class="participant-company">{{ roomInfo.invited[0].company }}</span>
        <span class="participant_new-message">
          новых сообщений: {{ roomInfo.unread }}
        </span>
      </div>
    </div>
    <template v-slot:stretch-link>
      <router-link
        :to="{ path: link }"
        class="stretch-link"
      />
    </template>
  </up-card>
</template>
<script>
export default {
  name: 'roomITem',
  props: {
    roomInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    link() {
      return `/chat/${this.roomInfo.uuid}`;
    },
    photoAlter() {
      return ['rose', 'orange', 'blue', 'purpure', 'lilac', 'green'][Math.floor(Math.random() * 6)];
    },
    firstLetter() {
      // eslint-disable-next-line no-unused-vars
      const [name, lastname, ...rest] = this.roomInfo.invited[0].name.toUpperCase().split(' ');
      return (name && lastname) ? `${name[0]}${lastname[0]}` : '';
    },
  },
};
</script>
<style lang="scss" scoped>
.room-info {
  &__wrapper {
    display: flex;
    gap: 10px;

    .chatroom {
      &-info {
        display: flex;
        flex: 1 1 100%;
        flex-direction: column;
        gap: 0px;

        .participant {
          &-persone {
            flex: 1 1 auto;
            color: #0D0D26;
            font-size: .9rem;
            white-space: nowrap;
            overflow: hidden;
          }
          &-company {
            font-size: .9rem;
            color: #AFB0B6;
            white-space: nowrap;
            overflow: hidden;
          }
          &_new-message {
            font-size: .8rem;
            color: #AFB0B6;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }
  &_photo {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    background-color: rgba(53, 104, 153, .125);

    & > img {
      max-width: 100%;
      max-height: 100%;
    }

    &__wrapper {
      flex: 0 0 60px;
      width: 60px;
      height: 60px;
    }
    &.alter-photo {
      font-size: 1.5rem;
      font-weight: 500;
      color: #FEFEFE;
    }
    &.rose {
      background: #FF7777;
    }
    &.orange {
      background: #FFAA77;
    }
    &.blue {
      background: #77AAFF;
    }
    &.purpure {
      background: #FF77DD;
    }
    &.lilac {
      background: #8850CC;
    }
    &.green {
      background: #40BB40;
    }
  }
}
</style>
