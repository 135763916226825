import { createApp } from 'vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import components from '@/components/library';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import i18n from './i18n';

const expoUp = createApp(App);

components.forEach((component) => {
  expoUp.component(component.name, component);
});

expoUp
  .use(store)
  .use(router)
  .use(i18n)
  .mount('#expoUp');
