<template>
<div class="chatroom-list">
    <div
      v-for="room in allChats"
      :key="room.uuid"
      class="chatroom-item"
    >
      <room-item
        :roomInfo="room"
      />
    </div>
</div>
</template>
<script>
// import { DateTime } from 'luxon';
import methodsChat from '@/components/mixins/methodsChat';
import participants from '@/components/mixins/methodsParticipants';
import roomItem from './roomItemList.vue';

export default {
  name: 'chatList',
  data() {
    return {
      allChats: [],
    };
  },
  components: { roomItem },
  mixins: [methodsChat, participants],
  mounted() {
    this.getChatList()
      .then((data) => {
        this.allChats = data.results;
      })
      .catch(() => {});
  },
};
</script>
<style lang="scss" scoped>
.chatroom {
  &-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &-item {
    flex: 0 1 auto;
  }
}
</style>
