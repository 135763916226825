import api from '@/components/mixins/baseApi';

export default {
  mixins: [api],
  methods: {
    getAllDate() {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiProgramsPath}/day-list`,
      })
        .then(({ results }) => results)
        .catch((error) => Promise.reject(error));
    },
    getHallList() {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiProgramsPath}/hall-list`,
      })
        .then(({ results }) => results)
        .catch((error) => Promise.reject(error));
    },
    getProgramList(page, hall = null) {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiProgramsPath}/${page}${hall ? `/${hall}` : ''}`,
      })
        .then((data) => data)
        .catch((error) => Promise.reject(error));
    },
  },
};
