<template>
  <button
    class="up-btn"
    @click="handleClick"
  >
    <slot />
  </button>
</template>
<script>
export default {
  name: 'upButton',
  props: {
    btnId: {
      default: null,
    },
  },
  methods: {
    handleClick() {
      this.$emit('click', this.btnId ? this.btnId : true);
    },
  },
};
</script>
<style lang="scss" scoped>
.up-btn {
  width: 100%;
  text-align: center;
  background: #fefefe;
  border: 2px solid #356899;
  color: #356899;
}
</style>
