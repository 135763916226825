<template>
  <header>
    <div class="row">
      <div class="col-auto btn-sm-col">
        <button
          class="btn btn-nav"
          @click="$emit('aside-click')"
          style="opacity: 0"
        >
          <i class="exu-chevron-left"></i>
        </button>
      </div>
      <div class="col">
          <h1>{{ mainTitle }}</h1>
          <p
            v-if="subTitle"
            class="subtitle"
          >
          {{ subTitle }}
        </p>
      </div>
      <div class="col-auto">
        <button
          v-if="showAsideButton"
          class="btn btn-nav btn-burger"
          @click="$emit('aside-click')"
        >
          <span />
        </button>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'MainHeader',
  props: {
    mainTitle: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    showAsideButton: {
      type: Boolean,
      default: true,
    },
    btnBack: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
header {
  position: sticky;
  top: 0;
  z-index: 1000;
  grid-area: header;
  display: grid;
  padding: 0 12px;
  background: linear-gradient(180deg, #356899 0%, #1A334C 100%);
  color: #f0f0f0;
  font-family: 'Roboto Condensed';
  // line-height: 1.15rem;

  & > .row {
    align-items: center;
  }

  h1 {
    margin: 0;
    font-size: 20px;
    text-align: center;
    font-weight: 600;
  }
  .subtitle {
    margin: 0;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
  }
  .btn-sm-col {
    width: 40px;
  }
  .btn-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0;
    border: none;
    color: #fefefe;
    font-size: 20px;
  }
  .btn-burger {
    flex-direction: column;
    justify-content: space-around;
    &::before, &::after, span {
      content: '';
      display: block;
      width: 100%;
      height: 3px;
      background: #fefefe;
    }
  }
}
</style>
