<template>
  <div class="notification__wrapper">
    <div class="row">
      <div class="col-12">
        <up-card v-if="!isLoading">
          <div
            class="notification__inner">
            <h1>{{ notification.title }}</h1>
            <div
              v-if="notification.description_format === 'html'"
              v-html="notification.description"
            />
            <p
              v-else
              class="break-line"
            >
              {{ notification.description }}
            </p>
          </div>
        </up-card>
      </div>
    </div>
  </div>
</template>
<script>
import notification from '@/components/mixins/methodsNotification';

export default {
  name: 'NotificationView',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    locale: {
      type: String,
    },
  },
  data() {
    return {
      notification: () => ({}),
      isLoading: true,
    };
  },
  mixins: [notification],
  watch: {
    locale() {
      this.$store.commit('setPageTitle', this.$t('notification.header_title'));
      this.$router.push({ name: 'notifications', params: { page: '1' } });
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.$t('notification.header_title'));
    Promise.all([
      this.getNotificationById(this.id),
    ])
      .then(([
        { results },
      ]) => {
        this.notification = { ...results };
        this.isLoading = false;
        if (this.notification.viewed === null) {
          this.notificationVeiwed(this.id);
          this.$store.commit(
            'setNewNotificationCount',
            this.$store.getters.getNewNotificationCount - 1,
          );
        }
      });
  },
  methods: {

  },
};
</script>
<style lang="scss" scoped>
.notification__wrapper {
  padding: 12px;
}
.notification__inner {
  padding: 12px 0;
}
.break-line {
  white-space: break-spaces;
}
</style>
