<!-- eslint-disable vuejs-accessibility/anchor-has-content -->
<template>
  <div class="banner__wrapper">
    <div class="timer">{{ timerBanner }}</div>
    <img :src="srcwBanner" alt="">
    <button
      v-if="banner.link"
      @click="handleBannerClick"
      class="stretch-button"
    />
    <popup-card
      v-if="showPopup"
      :is-open="showPopup"
      @show="handleCancelClick"
    >
    <p>
      Узнать подробнее<br>
      <a
        :href="banner.link"
        target="_blank"
        @click.stop.prevent="handleLinkClick"
      >
        {{ linkAlias }}
      </a>
    </p>
    </popup-card>
  </div>
</template>
<script>
export default {
  name: 'adBanner',
  props: ['banner'],
  data() {
    return {
      timerBanner: 0,
      timeoutShowBanner: null,
      showPopup: false,
    };
  },
  computed: {
    srcwBanner() {
      return this.banner.src;
    },
    linkAlias() {
      return this.banner.link.match(/http(?:s?):\/\/(.*\.)?[\w\d-]*\.\w*/)[0];
    },
  },
  mounted() {
    this.timerBanner = this.banner.timer;
    this.startInterval();
  },
  methods: {
    startInterval() {
      this.timeoutShowBanner = setInterval(() => {
        if (this.timerBanner > 0) {
          this.timerBanner -= 1;
        } else {
          this.$emit('update-banner');
          clearInterval(this.timeoutShowBanner);
        }
      }, 1000);
    },
    handleBannerClick() {
      this.showPopup = true;
      clearInterval(this.timeoutShowBanner);
    },
    handleCancelClick() {
      this.showPopup = false;
      this.startInterval();
    },
    handleLinkClick() {
      window.open(this.banner.link, '_blank');
      this.$emit('update-banner');
    },
  },
};
</script>
<style lang="scss">
.banner__wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  grid-column-start: content-start;
  grid-column-end: content-end;
  grid-row-start: header-start;
  grid-row-end: aside-end;
  background: #020202;
  // background: #fff;
  z-index: 1000;

  .timer {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    color: rgba(255, 255, 255, .75);
    border-radius: 50%;
    background: rgba(0,0,0,.15);
    box-shadow: 0 0 2px 2px rgba(255, 255, 255, .5);
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
  .stretch-link, .stretch-button {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    border: none;
    text-decoration: none;
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
