import api from '@/components/mixins/baseApi';

export default {
  mixins: [api],
  methods: {
    getSponsorList(page = 1) {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/sponsor/page/${page ? `${page}/` : '1/'}`,
      })
        .then((data) => data || {})
        .catch(() => false);
    },
    getSponsorPersone(id) {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/sponsor/${id}`,
      })
        .then((data) => data.results || {})
        .catch(() => false);
    },
    getSponsorCategory() {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/sponsor/category`,
      })
        .then((data) => data.results || {})
        .catch(() => false);
    },
  },
};
