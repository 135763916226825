<template>
  <div class="profile-top__wrapper">
    <div class="profile-photo__wrapper">
      <div
        v-if="photo"
        class="profile-photo"
      >
        <img
          :src="photo"
          alt=""
        >
      </div>
      <div
        v-else
        :class="photoAlter"
        class="profile-photo alter-photo"
      >
        <div>
          {{ firstLetter }}
        </div>
      </div>
    </div>
    <div class="profile-info">
      <p
        v-if="name"
        class="primary-data"
      >
        {{ name }}
      </p>
      <p
        v-if="position"
        class="secondary-data"
      >
        {{ position }}
      </p>
      <p
        v-if="company"
        class="secondary-data strong"
      >
        {{ company }}
      </p>
    </div>
    <div
      v-if="activityTag"
      class="activity-tag"
    >
      <span
        :class="{photoAlter: !photo}"
        class="item-tag"
      >
        {{ activityTag }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileTop',
  props: {
    photo: {
      type: String,
    },
    name: {
      type: String,
    },
    position: {
      type: String,
    },
    company: {
      type: String,
    },
    activityTag: {
      type: String,
      default: null,
    },
  },
  computed: {
    photoAlter() {
      return ['rose', 'orange', 'blue', 'purpure', 'lilac', 'green'][Math.floor(Math.random() * 6)];
    },
    firstLetter() {
      // eslint-disable-next-line no-unused-vars
      const [name, lastname, ...rest] = this.name.toUpperCase().split(' ');
      return (name && lastname) ? `${name[0]}${lastname[0]}` : '';
    },
  },
};
</script>

<style lang="scss">
.profile-top__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 12px;
  // background: #356899;
  background: url('@/assets/top-profile.png');
  background-position: center bottom;
  // temp style
  flex: 1 0 auto;
  width: 100%;
  max-height: 380px;
  // min-height: 200px;
  padding: 6px 6px 16px;

  // &::after {
  //   content: '';
  //   position: absolute;
  //   top: 100%;
  //   display: block;
  //   background: #356899;
  //   width: 100%;
  //   height: 17px;
  //   border-top-left-radius: 0;
  //   border-top-right-radius: 0;
  //   border-bottom-left-radius: 50% 100%;
  //   border-bottom-right-radius: 50% 100%;
  // }
}
.profile-photo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 112px;
  height: 112px;
  border-radius: 50%;
  overflow: hidden;

  // temp style
  // background: #0D0D26;
  &__wrapper {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: 0 auto;
    padding: 4px;
    background: rgba(255,255,255,.2);
  }
  & > img {
    max-width: 100%;
    max-height: 100%;
  }
  &.alter-photo {
    font-size: 48px;
    font-weight: 500;
    color: #FEFEFE;
  }
  &.rose {
    background: #FF7777;
  }
  &.orange {
    background: #FFAA77;
  }
  &.blue {
    background: #77AAFF;
  }
  &.purpure {
    background: #FF77DD;
  }
  &.lilac {
    background: #8850CC;
  }
  &.green {
    background: #40BB40;
  }
}
.profile-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  & .primary-data {
    color: #fefefe;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.3em;
    text-align: center;
    margin: 0;
  }
  & .secondary-data {
    color: #fefefe;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3em;
    text-align: center;
    margin: 0;

    &.strong {
      font-size: 18px;
      font-weight: 500;
    }
  }
}
.activity-tag {
  display: flex;
  justify-content: center;
  .item-tag {
    display: block;
    font-size: .86rem;
    color: #FFF;
    padding: 2px 12px;
    border-radius: 14px;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.2);
    &.rose {
      background: rgba(255, 119, 119, .2);
      border-color: #FF7777;
    }
    &.orange {
      background: rgba(255, 170, 119, .2);
      border-color: #FFAA77;
    }
    &.blue {
      background: rgba(119, 170, 255, .2);
      border-color: #77AAFF;
    }
    &.purpure {
      background: rgba(255, 119, 221, .2);
      border-color: #FF77DD;
    }
    &.lilac {
      background: rgba(136, 80, 204, .2);
      border-color: #8850CC;
    }
    &.green {
      background: rgba(64, 187, 64, .2);
      border-color: #40BB40;
    }
  }
}
</style>
