import api from '@/components/mixins/baseApi';

export default {
  mixins: [api],

  methods: {
    getChatRoomByUser(_userId) {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/chat/to/${_userId}`,
      })
        .then(({ results }) => results)
        .catch((error) => error);
    },
    getChatRoom(_uuid) {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/chat/${_uuid}`,
      })
        .then((data) => data)
        .catch((error) => error);
    },
    getNewMessage(_uuid, _messageID) {
      // wapi/v1/ru/2/chat/<chat_uuid>/<message_id>
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/chat/${_uuid}/${_messageID}`,
      })
        .then((data) => data)
        .catch((error) => error);
    },
    sendMessage(_props) {
      return this.baseAxios({
        method: 'post',
        url: `${this.$store.getters.apiBasePath}/chat/${_props.uuid}`,
        data: {
          user_id: _props.participant,
          message: _props.text,
        },
      })
        .then((data) => data)
        .catch((error) => error);
    },
    getChatList(_page) {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/chat/page${_page ? `/${_page}` : ''}`,
      })
        .then((data) => data)
        .catch((error) => error);
    },
  },
};
