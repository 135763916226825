import api from '@/components/mixins/baseApi';

export default {
  mixins: [api],
  methods: {
    // eslint-disable-next-line no-unused-vars
    getLectureQuestion(id) {
      return this.baseAxios({
        method: 'get',
        // url: `${this.$store.getters.apiProgramsPath}/20240227`,
        url: `${this.$store.getters.apiBasePath}/lecture/${id}/question/list`,
      })
        .then((data) => data.results || [])
        .catch((error) => Promise.reject(error));
    },
    sendQuestion(props) {
      return this.baseAxios({
        method: 'post',
        url: `${this.$store.getters.apiBasePath}/lecture/${props.lecture_id}/question/`,
        data: {
          text: props.text,
        },
      })
        .then((data) => data?.results || [])
        .catch((error) => Promise.reject(error));
    },
    doLiking(props) {
      const likeAction = props.liked_it ? 'unlike' : 'like';
      return this.baseAxios({
        method: 'put',
        url: `${this.$store.getters.apiBasePath}/lecture/${props.lecture_id}/question/${props.question_id}/${likeAction}`,
      })
        .then((data) => data?.results || [])
        .catch((error) => Promise.reject(error));
    },
  },
};
