<template>
  <grid-menu
    :menu-items="menuItems"
    :locale="locale"
    @switch-locale="changeLocale"
  />
</template>

<script>
import gridMenu from '@/components/shared/asideMenu/gridMenu.vue';

export default {
  name: 'DashboardView',
  components: { gridMenu },
  props: {
    locale: {
      type: String,
      default: 'ru',
    },
  },
  // data() {
  //   return
  // },
  watch: {
    locale() {
      this.$store.commit('setPageTitle', this.$store.getters.getMainTitle);
    },
  },
  computed: {
    menuItems() {
      return [
        {
          moduleName: 'about',
          iconName: 'exu-home',
          anchor: this.$t('mainMenu.about'),
          isDisabled: false,
        },
        {
          moduleName: 'staticPage',
          alias: 'regulations',
          iconName: 'exu-document',
          anchor: this.$t('mainMenu.regulations'),
        },
        {
          moduleName: 'staticPage',
          alias: 'faq',
          iconName: 'exu-doc-question-1',
          anchor: this.$t('mainMenu.faq'),
        },
        {
          moduleName: 'program',
          iconName: 'exu-calendare',
          anchor: this.$t('mainMenu.program'),
        },
        {
          moduleName: 'speakers',
          iconName: 'exu-mic',
          anchor: this.$t('mainMenu.speakers'),
        },
        {
          moduleName: 'staticPage',
          alias: 'faq',
          hash: '#faq-speaker-question',
          iconName: 'exu-question',
          anchor: this.$t('mainMenu.faq-question'),
        },
        {
          moduleName: 'participants',
          iconName: 'exu-users',
          anchor: this.$t('mainMenu.participants'),
        },
        {
          moduleName: 'partners',
          iconName: 'exu-award-3',
          anchor: this.$t('mainMenu.partners'),
        },
        {
          moduleName: 'staticPage',
          alias: 'map',
          iconName: 'exu-map',
          anchor: this.$t('mainMenu.layout'),
        },
        {
          moduleName: 'chatView',
          iconName: 'exu-mail-2',
          bubble: this.newChatUnread,
          anchor: this.$t('mainMenu.messages'),
        },
        {
          isVisible: false,
        },
        {
          moduleName: 'notifications',
          iconName: 'exu-annotation-alert',
          bubble: this.newNotification,
          anchor: this.$t('mainMenu.notification'),
        },
      ];
    },
    newNotification() {
      return this.$store.getters.getNewNotificationCount < 100 ? this.$store.getters.getNewNotificationCount : '99+';
    },
    newChatUnread() {
      return this.$store.getters.getUnreadChatCount < 100 ? this.$store.getters.getUnreadChatCount : '99+';
    },
  },
  mounted() {
    // this.$router.currentRoute._value.name
    this.$store.commit('setPageTitle', this.$store.getters.getMainTitle);
  },
  methods: {
    changeLocale() {
      this.$emit('switch-locale', this.locale === 'ru' ? 'en' : 'ru');
    },
  },
};
</script>

<style lang="scss">
.menu__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: 100%;
  height: 100%;
  // background: #FFF;
  // /* box-shadow: 0px -10px 10px 10px rgba(0, 0, 0, .25); */
  // transition: transform .5s;
  // border-left: 1px solid #e0e0e0;
}
</style>
