<template>
  <div class="lecture-item">
    <div class="lecture-time">
      <p class="time-start">
        {{ $t('shared.lecture.start_at_label') }} {{ timeStart }}
      </p>
      <p class="time-start">
        {{ lecture.duration }} {{ $t('shared.lecture.minuts') }}
      </p>
    </div>
    <div class="lecture-title">
      <p>
        {{ lecture.title }}
      </p>
    </div>
    <div
      v-if="isModerator && isSpeakers"
      class="lecture-moderate">
      <span class="moderate-item">{{ $t('shared.lecture.moderator') }}:</span>
        <span
          v-for="moderator in ModeratorList"
          :key="moderator.id"
          class="moderate-item"
        >
          {{ moderator.name}}
        </span>
    </div>
    <div
      v-if="isSpeakers"
      class="lecture-speaker">
      <i class="exu-mic"/>
      <router-link
        v-for="speaker in lecture.speakers"
        :key="speaker.id"
        :to="{ path: speakerLink(speaker.id) }"
        class="speaker-item"
      >
        {{ speaker. name}}
      </router-link>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { DateTime } from 'luxon';

export default {
  name: 'LectureItem',
  props: {
    lecture: {
      type: Object,
      default: () => ({}),
    },
    moderators: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    timeStart() {
      // TODO fix global UTC
      return DateTime.fromMillis(this.lecture.start_ts).setZone('UTC+0').toFormat('H:mm');
    },
    isModerator() {
      return this.lecture.moderators.length > 0 || null;
    },
    ModeratorList() {
      if (this.lecture.moderators.length > 0) {
        return this.lecture.moderators;
      }
      return [];
    },
    isSpeakers() {
      return this.lecture.speakers.length > 0;
    },
  },
  methods: {
    // moderatorLink(id) {
    //   return `/speaker/profile/${id}`;
    // },
    speakerLink(id) {
      return `/speaker/profile/${id}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.lecture-item {
  font-size: 14px;
  .lecture-time {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #356899;

    &>p {
      margin: 0;
    }
  }

  .lecture-title {
    color: #356899;
    font-style: normal;
    &>p {
      margin: 0;
    }
  }

  .lecture-speaker, .lecture-moderate {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 15px;
    color: #95969D;

    .speaker-item, .moderate-item {
      display: block;
      color: #2c3e50;
      font-size: 14px;
      text-decoration: none;
      outline: none;
      white-space: nowrap;

      &.current {
        color: #356899;
      }
    }
  }
}
</style>
