<!-- eslint-disable vue/require-v-for-key -->
<template>
  <div class="partners-list">
    <div class="row">
        <template
          v-for="partner in partnerList"
          :key="partner.id"
        >
          <h2 v-if="partner.type === 'title'">
            {{ partner.name }}
          </h2>
          <div
            v-if="partner.type === 'partner'"
            :class="`col-${partner.size}`"
          >
            <partner-card
              :photo="partner.logo"
              :partner-id="partner.id"
            />
          </div>
        </template>
    </div>
    <pre-loader v-if="isLoading"/>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import sponsors from '@/components/mixins/methodsSponsors';
import banners from '@/components/mixins/methodsBanners';

export default {
  name: 'ListPartners',
  props: ['launchDownload', 'locale'],
  data() {
    return {
      partnerList: [],
      categoryList: null,
      isLoading: true,
      curPage: 1,
      countPage: null,

      itPriority: null,
    };
  },
  mixins: [sponsors, banners],
  watch: {
    launchDownload() {
      if (!this.isLoading && this.launchDownload) {
        this.curPage += 1;
        this.getPage();
      }
    },
    locale() {
      this.$store.commit('setPageTitle', this.$t('partnerList.header_title'));
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.$t('partnerList.header_title'));
    Promise.all([
      this.getBanner('sponsor', this.$store.getters.getBannerPartnerTs),
      this.getSponsorCategory(),
    ])
      .then(([
        banner,
        catgory,
      ]) => {
        this.isLoading = false;
        if (banner && Object.keys(banner).length) {
          this.$emit('showBanner', banner);
          const nowMilis = DateTime.now().toMillis();
          localStorage.setItem('bannerPartnerTs', nowMilis);
          this.$store.commit('setBannerPartnerTs', nowMilis);
        }

        this.categoryList = new Map([...catgory].map((item) => ([item.id, { ...item }])));
        this.getPage();
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    getPage() {
      this.isLoading = true;
      if (this.countPage === null || this.curPage <= this.countPage) {
        this.getSponsorList(this.curPage)
          .then((data) => {
            this.isLoading = false;
            this.$emit('turnOff', {
              stateDownload: false,
              stateCheckInbox: false,
            });
            this.partnerList = [
              ...this.partnerList,
              ...this.presetPartnerLis(data.results),
            ];
            this.countPage = data.num_pages;
            this.curPage = data.page;
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.isLoading = false;
        this.$emit('turnOff', {
          stateCheckInbox: true,
        });
      }
    },
    presetPartnerLis(list) {
      const complitList = [];
      list.forEach((item) => {
        if (this.itPriority !== item.category_id) {
          this.itPriority = item.category_id;
          complitList.push({
            ...this.categoryList.get(item.category_id),
            type: 'title',
          });
        }
        complitList.push({
          ...item,
          size: this.categoryList.get(item.category_id).size,
          type: 'partner',
        });
      });
      return complitList;
    },
  },
};
</script>

<style lang="scss">
.partners-list {
  padding: 20px;

  & > .row {
    margin: 0 -10px;
    row-gap: 20px;
    & > .col-6, .col-12 {
      padding: 0 10px;
    }
  }
  h2 {
    margin: 0;
    text-align: center;
  }
}
</style>
