<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <div class="form__wrapper">
    <up-card>
      <form>
        <div
          v-if="infoMessage"
          class="mb-3"
        >
          <div class="alert alert-danger">
            {{ infoMessage }}
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label">
            {{ $t('questionList.form.label_question') }}
          </label>
          <textarea
            v-model="textQuestion"
            class="form-control"
          />
        </div>
        <div class="row">
          <div class="col-6">
            <button
              type="button"
              class="btn btn-cancel"
              @click="closeForm"
            >
              {{ $t('shared.form.btn_cancel') }}
            </button>
          </div>
          <div class="col-6">
            <button
              type="button"
              class="btn btn-send"
              @click="sendForm"
            >
              {{ $t('shared.form.btn_send') }}
            </button>
          </div>
        </div>
      </form>
    </up-card>
  </div>
</template>
<script>
import lectures from '@/components/mixins/methodsLectures';

export default {
  name: 'questionForm',
  mixins: [lectures],
  props: ['lecture'],
  data() {
    return {
      textQuestion: null,
      infoMessage: null,
    };
  },
  methods: {
    sendForm() {
      if (this.textQuestion) {
        this.sendQuestion({
          lecture_id: this.lecture.id,
          text: this.textQuestion,
        })
          .then(() => {
            this.$emit('updateShowForm', false);
          })
          .catch(() => {
            this.infoMessage = this.$t('Erorrs.server_500');
          });
      }
    },
    closeForm() {
      this.$emit('updateShowForm', false);
    },
  },
};
</script>
<style lang="scss" scoped>
textarea {
  min-height: calc(var(--vh, 1vh) * 20);
}
.form__wrapper {
  padding: 12px;
  flex: 1 1 100%;

  .btn-cancel, .btn-send {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    border-radius: 20px;
  }
  .btn-cancel {
    color: #356899;
    border: 2px solid #356899;
    background: #FEFEFE;
    &:active {
      background: #356899;
      color: #FEFEFE;
    }
  }
  .btn-send {
      border: 2px solid #d76535;
      background: #FEFEFE;
      color: #d76535;
    &:active {
      background: #d76535;
      color: #FEFEFE;
    }
  }
}
</style>
