// eslint-disable-next-line import/no-extraneous-dependencies
import { DateTime } from 'luxon';

export default {
  methods: {
    timeStart(startTs) {
      return DateTime.fromMillis(startTs);
    },
    timeEnd(durationTs) {
      return this.timeStart.plus(durationTs);
    },
    timeStartEnd(timeStart, timeEnd) {
      return `${timeStart.toFormat('H:mm')} - ${timeEnd.toFormat('H:mm')}`;
    },
    dateEvent(timeStart) {
      return timeStart.setLocale('ru').toFormat('L MMMM');
    },
    dateEventLong(ts) {
      return DateTime.fromMillis(ts).setLocale('ru').toFormat('d MMMM yyyy');
    },
    timeEvent(ts) {
      return DateTime.fromMillis(ts).setLocale('ru').toFormat('HH:mm');
    },
  },
};
