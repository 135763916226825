<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <up-card class="overflow-unset">
    <div :class="{ 'section__wrapper': true, 'isOpen': isOpenSection }">
      <div
        class="section-line"
        @click="isOpenSection = !isOpenSection"
      >
        <p class="section-time">
          {{ timeStartEnd }}
        </p>
        <p class="section-place">
          {{ section.place }}
        </p>
      </div>
      <div
        class="section-line"
        @click="isOpenSection = !isOpenSection"
      >
        <p class="section-title">
          {{ sectionName }} {{ sectionTitle }}
        </p>
      </div>
      <div
        v-if="isModerators"
        class="section-moderate">
        <span class="moderate-item">{{ $t('shared.lecture.moderator') }}:</span>
        <span
          v-for="moderator in section.moderators"
          :key="moderator.id"
          class="moderate-item"
        >
          {{ moderator.name}}
        </span>
      </div>
      <div
        v-if="isSpeakers"
        class="section-speaker">
        <i class="exu-mic"/>
        <router-link
          v-for="speaker in section.speakers"
          :key="speaker.id"
          :to="{ path: speakerLink(speaker.id) }"
          class="speaker-item"
        >
          {{ speaker. name}}
        </router-link>
      </div>
      <div
        v-if="isOpenSection"
        class="section-lecture"
      >
        <lecture-item
          v-for="lecture in section.lectures"
          :key="lecture.id"
          :lecture="lecture"
          :moderators="section.moderators"
        />
      </div>
      <div
        v-if="isBtnMore"
        class="section-line button-line"
      >
        <div class="col-8">
          <button
            class="btn btn-sm btn-more"
            @click="isOpenSection = !isOpenSection"
          >
            <i
              v-if="!isOpenSection"
              class="exu-chevron-down"
            />
            <i
              v-else
              class="exu-chevron-up"
            />
          </button>
        </div>
        <div class="col-4">
          <router-link
            :to="{ name: 'questions', params: { section_id: this.section.id } }"
            class="btn btn-sm btn-accent"
          >
            <i class="exu-question" />
          </router-link>
        </div>
      </div>
    </div>
  </up-card>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { DateTime } from 'luxon';

export default {
  name: 'SectionCard',
  props: {
    section: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isOpenSection: false,
    };
  },
  computed: {
    checkLecture() {
      return !!this.lecture;
    },
    timeStart() {
      // TODO fix global UTC
      return DateTime.fromMillis(this.section.start_ts).setZone('UTC+0');
    },
    timeEnd() {
      return this.timeStart.plus(this.section.duration_ts);
    },
    timeStartEnd() {
      return this.timeStart ? `${this.timeStart.toFormat('H:mm')} - ${this.timeEnd.toFormat('H:mm')}` : '';
    },
    dateEvent() {
      return this.timeStart ? this.timeStart.setLocale('ru').toFormat('L MMMM') : '';
    },
    sectionName() {
      return this.section.name
        ? `${this.section.name}${this.section.name[this.section.name.length - 1] !== '.' ? '.' : ''}`.trim()
        : null;
    },
    sectionTitle() {
      return this.section.title
        ? `${this.section.title}${this.section.title[this.section.title.length - 1] !== '.' ? '.' : ''}`.trim()
        : null;
    },
    isBtnMore() {
      return this.section.lectures.length > 0;
    },
    isModerators() {
      return this.section.moderators.length > 0;
    },
    isSpeakers() {
      return this.section.speakers.length > 0 && !this.isOpenSection;
    },
  },
  methods: {
    // moderatorLink(id) {
    //   return `/speaker/profile/${id}`;
    // },
    speakerLink(id) {
      return `/speaker/profile/${id}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.section__wrapper {
  display: flex;
  padding: 2px 13px;
  flex-direction: column;
  align-items: stretch;
  gap: 5px;

  p {
    margin: 0;
    font-size: 14px;
    line-height: 130%; /* 18.2px */
    letter-spacing: -0.14px;
  }

  &.isOpen {
    .button-line {
      position: sticky;
      bottom: 0;
    }
  }
}
.section-line {
  display: flex;
  justify-content: space-between;
}
.section-title {
  display: flex;
  justify-content: space-between;
  row-gap: 15px;
  color: #356899;
  // font-size: 14px;
  // line-height: 1.1rem;
  font-style: normal;
}
.section-time, .section-place {
  font-weight: 400;
  color: #356899;
  font-weight: 500;
}
.button-line {
  display: flex;
  justify-content: center;
  margin: 0 -13px;
  padding: 8px 13px 4px;
  background: #FEFEFE;
  gap: 16px;
}
.section-speaker, .section-moderate {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: 15px;
  color: #95969D;

  .speaker-item, .moderate-item {
    display: block;
    color: #2c3e50;
    font-size: 14px;
    text-decoration: none;
    outline: none;
    white-space: nowrap;

    &.current {
      color: #356899;
    }
  }
}
.section-lecture {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.btn-more {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 38px;
  font-size: 1.25rem;
  color: #356899;
  border: 2px solid #356899;
  border-radius: 16px;
  background: #FFFFFF;

  &:active {
    color: #FFFFFF;
    background: #356899;
  }
}
.btn-accent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 38px;
  font-size: 1.25rem;
  color: #D76535;
  border: 2px solid #D76535;
  border-radius: 16px;
  background: #FFFFFF;

  &:active {
    color: #FFFFFF;
    background: #D76535;
  }
}
</style>
