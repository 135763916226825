<template>
  <div class="expoup__wrapper">
    <main-header
      :main-title="mainTitle"
      :sub-title="subTitle"
      :show-aside-button="!isDashboard"
      @aside-click="showingAside"
    />
    <div
      id="content__wrapper"
      class="content__wrapper"
      :class="{'full-content': isDashboard}"
    >
      <pre-loader v-if="loading"/>
      <router-view
        v-if="appLoaded"
        :locale="locale"
        :launch-download="trunkOnDownload"
        :updatePwaExist="updatePWAExist"
        @update-pwa="updatePWA(true)"
        @turn-off="eventTrunkDownload"
        @loading="showingPreloader"
        @show-banner="showingBanner"
        @switch-locale="switchLocale"
      />
    </div>
    <bottom-pwa-update
      v-if="updatePWAExist"
      :updatePwaExist="updatePWAExist"
      @update-pwa="updatePWA(true)"
      @denide-update-pwa="updatePWA(false)"
    />
    <bottom-nav />
    <main-aside-menu
      v-if="showAside"
      :locale="locale"
      :updatePwaExist="updatePWAExist"
      @switch-locale="switchLocale"
      @update-pwa="updatePWA(true)"
    />
    <div
      v-if="launchScreen"
      class="launch-screen"
    >
      <img
        src="/img/entrance.jpg"
        alt=""
      />
    </div>
    <ad-banner
      v-if="adBannerShowing"
      :banner="adBanner"
      @update-banner="adBannerShowing = false"
    />
  </div>
</template>

<script>
// import { DateTime } from 'luxon';
import global from '@/components/mixins/methodsGlobal';
import updatePwa from '@/components/mixins/methodsPwaUpdate';
import { DateTime } from 'luxon';
import adBanner from '@/components/shared/adBannerView.vue';
import bottomPwaUpdate from '@/components/shared/updPWA.vue';

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { adBanner, bottomPwaUpdate },
  data() {
    return {
      expoData: {},

      showedLauncScreen: false,
      timeoutLS: null,

      // showBanner: false,
      timerBanner: 0,
      timeoutShowBanner: null,
      timeoutCheckBanner: null,

      adBanner: {},
      adBannerShowing: false,

      showAside: false,
      mainContent: null,
      appLoaded: false,
      trunkOnDownload: false,
      loadingInbox: false,
      loading: false,
    };
  },
  mixins: [global, updatePwa],
  created() {
  },
  computed: {
    mainTitle() {
      return (this.showAside || !this.$store.getters.getPageTitle)
        ? this.$store.getters.getMainTitle : this.$store.getters.getPageTitle;
    },
    dateSubtitle() {
      const start = DateTime.fromMillis(this.expoData.start_ts);
      const end = DateTime.fromMillis(this.expoData.end_ts);
      // TODO переработать вывод и избавиться от повторений
      if (start.day !== end.day || start.month !== end.month) {
        if (start.month !== end.month) {
          if (this.$store.getters.getLang === 'ru') {
            return `${start.setLocale('ru').toFormat('d MMMM')} - ${end.setLocale('ru').toFormat('d MMMM yyyy')}`;
          }
          return `${start.setLocale(this.$store.getters.getLang).toFormat('d MMMM')} - ${end.setLocale(this.$store.getters.getLang).toFormat('d MMMM, yyyy')}`;
        }
        if (this.$store.getters.getLang === 'ru') {
          return `${start.setLocale('ru').toFormat('d')} - ${end.setLocale('ru').toFormat('d MMMM yyyy')}`;
        }
        return `${start.day} - ${end.setLocale(this.$store.getters.getLang).toFormat('d MMMM, yyyy')}`;
      }
      if (this.$store.getters.getLang === 'ru') {
        return end.setLocale('ru').toFormat('d MMMM yyyy');
      }
      return start.setLocale(this.$store.getters.getLang).toFormat('yyyy d MMMM');
    },
    subTitle() {
      return (this.showAside || this.isDashboard) && this.expoData.start_ts && this.expoData.end_ts ? this.dateSubtitle : '';
    },
    contentOffsetHeight() {
      return this.mainContent.offsetHeight;
    },
    launchScreen() {
      if (!this.showedLauncScreen) {
        return this.expoData.launchScreen || null;
      }
      return null;
    },
    isDashboard() {
      return this.$route.name === 'DashboardView';
    },
    locale: {
      get() {
        return this.$i18n.locale;
      },
      set(locale) {
        localStorage.setItem('__exup__locale', locale);
        this.$i18n.locale = locale;
      },
    },
    showBanner: {
      get() {
        return this.expoData.bannerSrc || null;
      },
      set(src) {
        this.expoData.bannerSrc = src;
      },
    },
  },
  watch: {
    $route() {
      this.showAside = false;
    },
  },
  beforeMount() {
    this.screenResize();
    window.addEventListener('resize', this.screenResize);
  },
  mounted() {
    this.checkToken();
    this.mainContent = document.getElementById('content__wrapper');
    this.mainContent.addEventListener('scroll', this.handleScrollToBottom);
    this.declinedUpdTs = localStorage.getItem('__cancel_update__');
    Promise.all([
      this.getConfig(this.$store.getters.getExhId) || null,
      this.checkInbox(),
    ])
      .then(([
        config,
        { notification, chat },
      ]) => {
        this.$store.commit('setMainTitle', { lang: 'ru', text: config.locale.name.ru });
        this.$store.commit('setMainTitle', { lang: 'en', text: config.locale.name.en });
        this.expoData.start_ts = config.date_start_ts;
        this.expoData.end_ts = config.date_end_ts;
        document.querySelector('title').innerText = config.appname;
        document.querySelector("link[rel~='icon']").href = config.favicon['64'];
        this.expoData.launchScreen = config.launch_screen;

        if (!this.showedLauncScreen) {
          this.timeoutLS = setTimeout(() => { this.showedLauncScreen = true; }, 2000);
        }

        if (notification || chat) {
          this.recalcNotification({ notification, chat });
        }
        this.appLoaded = true;
      })
      .catch(() => {});
  },
  methods: {
    showingPreloader(state) {
      this.loading = state;
    },
    showingAside() {
      this.showAside = !this.showAside;
      this.checkInbox()
        .then(({ notification, chat }) => {
          if (notification || chat) {
            this.recalcNotification({ notification, chat });
          }
        })
        .catch(() => {});
    },
    switchLocale(val) {
      // this.showingAside();
      this.showAside = false;
      this.locale = val;
      this.$store.commit('setLang', val);
      // this.$router.go();
    },
    showingBanner({ src, link }) {
      this.adBanner = {
        src,
        link,
        timer: 5,
      };
      this.adBannerShowing = true;
    },
    recalcNotification({ notification = 0, chat = 0 }) {
      this.$store.commit('setNewNotificationCount', notification);
      this.$store.commit('setUnreadChatCount', chat);
    },
    eventTrunkDownload({ stateDownload, stateCheckInbox }) {
      if (stateDownload !== undefined) {
        this.trunkOnDownload = stateDownload;
      }
      if (stateCheckInbox !== undefined) {
        this.loadingInbox = stateCheckInbox;
      }
    },
    screenResize() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    handleScrollToBottom() {
      requestAnimationFrame(this.redrawLayer);
    },
    redrawLayer() {
      const ofsetHeight = this.mainContent.children[0].offsetHeight;
      if (ofsetHeight - this.mainContent.scrollTop < 800) {
        this.trunkOnDownload = true;

        // TODO вынести в отдельный метод проверки состояния
        if (!this.loadingInbox) {
          this.loadingInbox = true;
          this.checkInbox()
            .then(({ notification, chat }) => {
              this.recalcNotification({ notification, chat });
            })
            .catch(() => {
              this.loadingInbox = false;
            });
        }
      }
    },
  },
  // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
  beforeDestroy() {
    window.removeEventListener('resize', this.screenResize);
    this.mainContent.removeEventListener('scroll', this.handleScrollToBottom);
  },
};
</script>

<style lang="scss">
@import url('@/assets/style/fonts.scss');
body {
  margin: 0;
  padding: 0;
  background: #303030;
  & * {
    box-sizing: border-box;
  }
}
.expoup__wrapper {
  display: grid;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  grid-template-columns:
    [header-start content-start aside-start bottom-nav-start] 1fr
    [header-end content-end bottom-nav-end aside-end];
  grid-template-rows:
    [header-start] 76px
    [header-end content-start aside-start] 1fr
    [system-start] 100px
    [content-end bottom-nav-start] auto [bottom-nav-end aside-end];
  background: #F6F6FA;
  font-size: 16px;
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
}
.content__wrapper {
  grid-column-start: content-start;
  grid-column-end: content-end;
  grid-row-start: content-start;
  grid-row-end: content-end;
  overflow: hidden auto;
  z-index: 10;

  &.full-content {
    grid-column-start: content-start;
    grid-column-end: content-end;
    grid-row-start: aside-start;
    grid-row-end: aside-end;
    z-index: 180;
  }
}
.bottom-update__wrapper {
  grid-column-start: content-start;
  grid-column-end: content-end;
  grid-row-start: system-start;
  grid-row-end: bottom-nav-end;
  z-index: 190;
}
.bottom-nav__wrapper {
  grid-area: bottom-nav;
  z-index: 160;
}

aside {
  grid-area: aside;
  display: flex;
  justify-content: end;
  width: 100%;
  background: rgba(0, 0, 0, .5);
  transition: opacity .5s;
  z-index: 200;

  nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;
    }
  }
}
.launch-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  grid-column-start: content-start;
  grid-column-end: content-end;
  grid-row-start: header-start;
  grid-row-end: aside-end;
  z-index: 1100;
  background: #F6F6FA;

  & > img {
    // max-width: 100%;
    max-height: 100%;
  }
}
.container-static {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px;
}
.inner-html {
  & p {
    margin-bottom: 8px;
  }
  & ul {
    padding: 16px;
    margin: 0;
  }
  a {
    color: #356899;
    text-decoration: none;
  }
  :last-child {
    margin-bottom: 0;
  }
}
// ===============
.overflow-unset {
  overflow: unset;
}
</style>
