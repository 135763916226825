/* eslint-disable no-console */
// eslint-disable-next-line import/no-extraneous-dependencies
import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    // eslint-disable-next-line no-unused-vars
    ready(reg) {
      // console.log(
      //   'App is being served from cache by a service worker.\n'
      //   + 'For more details, visit https://goo.gl/AFskqB',
      // );
    },
    registered() {
      // console.log('Service worker has been registered.');
      // const subscription = await register.pushManager.subscribe({
      //     userVisibleOnly: true,
      //     applicationServerKey: publicVapidKey,
      // });

      // await fetch("/subscribe", {
      //     method: "POST",
      //     body: JSON.stringify(subscription),
      //     headers: {
      //         "Content-Type": "application/json",
      //   }
      // })
    },
    cached() {
      // console.log('Content has been cached for offline use.');
    },
    updatefound() {
      // console.log('New content is downloading.');
    },
    updated(registration) {
      // console.log('New content is available; please refresh.');
      document.dispatchEvent(
        // eslint-disable-next-line no-undef
        new CustomEvent('swUpdated', { detail: registration }),
      );
    },
    offline() {
      // console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.log(`${process.env.BASE_URL}service-worker.js`);
      console.error('Error during service worker registration:', error);
    },
  });
}
