<template>
  <div class="profile__wrapper">
    <profile-top
      :photo="photo"
      :name="name"
      :position="position"
      :company="company"
    />
    <div class="profile-content__wrapper">
      <div class="profile-content">
        <p class="description-paragraph">
          {{ description }}
        </p>
      </div>
    </div>
    <div
      v-if="isSection"
      class="profile-content__wrapper"
    >
      <div class="profile-content stretch">
        <p class="title">
          {{ $t('speakerProfile.lecture_title') }}
        </p>
        <div
          v-for="section in sections"
          :key="section.id"
          class="section-block"
        >
          <program-card
            v-for="lecture in section.lectures"
            :key="lecture.id"
            :section-name="section.name || null"
            :section-title="section.title || null"
            :section-start="section.start_ts || null"
            :section-speaker="section.speackers || []"
            :section-moderators="section.moderators || []"
            :lecture="lecture"
            @speaker-update="loadProfile"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import speackers from '@/components/mixins/methodsSpeackers';

export default {
  name: 'SpeakerProfile',
  props: {
    id: {
      requared: true,
    },
    locale: {
      type: String,
    },
  },
  data() {
    return {
      speaker: {
        type: Object,
        default: () => ({}),
      },
    };
  },
  mixins: [speackers],
  computed: {
    name() {
      return this.speaker.name || '';
    },
    position() {
      return this.speaker.position || '';
    },
    company() {
      return this.speaker.company || '';
    },
    photo() {
      return this.speaker.photo || '';
    },
    description() {
      return this.speaker.description || '';
    },
    isSection() {
      return this.speaker.sections && this.speaker.sections.length > 0;
    },
    sections() {
      return this.speaker.sections.map((section) => {
        if (section.lectures.length > 0) {
          return section;
        }
        return {
          ...section,
          lectures: [
            {
              start_ts: section.start_ts,
              duration_ts: section.duration_ts,
              moderators: [],
              speakers: [...section.speakers],
            },
          ],
        };
      });
    },
  },
  watch: {
    locale() {
      this.$store.commit('setPageTitle', this.$t('speakerProfile.header_title'));
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.$t('speakerProfile.header_title'));
    this.loadProfile(this.id);
  },

  methods: {
    loadProfile(id) {
      this.getSpeackerPersone(id)
        .then((result) => {
          this.speaker = { ...result };
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.profile__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  min-height: 100%;
  padding-bottom: 24px;
}
.profile-content__wrapper {
  display: flex;
  align-items: center;
  flex: 3 1 100%;
  align-self: stretch;
}
.profile-content {
  flex: 1 1 100%;
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  &.stretch {
    align-items: stretch;
  }
  .description-paragraph {
    color: #95969D;
    // font-family: Roboto;
    // font-size: 14px;
    text-align: justify;
    margin: 0;
  }
  .section-block {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  .title {
    color: #101010;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 20.8px */
    letter-spacing: -0.16px;
    margin: 0;
  }
  .theme {
    color: #101010;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    letter-spacing: -0.16px;
    margin: 0;
  }
  .moder-line {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    & > p {
      color: #000;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 18.2px */
      letter-spacing: -0.14px;
      margin: 0;
    }
  }
  .other-persone {
    display: flex;
    padding-left: 40px;
    gap: 2px;
    flex-wrap: wrap;

    & > span {
      color: #95969D;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 18.2px */
      letter-spacing: -0.14px;
      margin: 0;
      text-wrap: nowap;
    }
  }
}
</style>
