<template>
  <div class="page__wrapper">
    <div class="container-static">
      <template
        v-for="block in blocks"
        :key="block.id"
      >
        <div
          v-if="block.type === 'image'"
          class="image__wrapper">
          <img
            v-if="block.src"
            :src="block.src"
            alt=""
            srcset=""
          >
        </div>
        <div
          v-if="block.type === 'html'"
          v-html="block.value"
          class="inner-html"
        ></div>
        <p
          v-if="block.type === 'text'"
          class="break-line"
        >
          {{ description }}
        </p>
      </template>
    </div>
  </div>
</template>
<script>
import StaticPage from '@/components/mixins/methodsStaticPage';

export default {
  name: 'StaticPageView',
  mixins: [StaticPage],
  props: {
    alias: {
      type: [String],
      required: true,
    },
  },
  data() {
    return {
      blocks: [],
    };
  },
  watch: {
    alias() {
      this.loadPage();
    },
    $route(to) {
      if (to.hash) {
        document.getElementById(to.hash.replace('#', '')).scrollIntoView();
      } else {
        document.getElementById('content__wrapper').scrollTo(0, 0);
      }
    },
  },
  beforeMount() {
    this.loadPage();
  },
  mounted() {
    if (this.$route.hash) {
      setTimeout(() => {
        document.getElementById(this.$route.hash.replace('#', '')).scrollIntoView();
      }, 500);
    }
  },
  methods: {
    loadPage() {
      this.getPage(this.alias)
        .then((content) => {
          this.$store.commit('setPageTitle', content.title || null);
          this.blocks = [...content.blocks];
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss">
.image__wrapper {
  display: flex;
  justify-content: center;
  overflow: auto;
  img {
    // max-height: calc(var(--vh, 1vh) * 100 - 166px);
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
