<template>
  <component
    v-if="isVisible"
    :is="isDisabled ? 'span' : 'router-link'"
    :class="{ 'menu-point' : isDisabled }"
    :to="{
      name: itemProps.moduleName,
      hash: itemProps.hash || '',
      params: itemProps.alias ? { alias: itemProps.alias } : {}
    }"
  >
    <div class="icon__wrapper">
      <i :class="itemProps?.iconName"></i>
        <span
          v-if="bubble"
          class="bubble"
        >
        {{ bubble }}
        </span>
    </div>
    <span>{{ itemProps?.anchor }}</span>
  </component>
  <div
    v-else
    class="menu-point"
  />
</template>

<script>
/*
itemProps.moduleName: []
itemProps.alias: []
itemProps.iconName: []
itemProps.anchor: []
itemProps.bubble: []
itemProps.isDisabled: []
itemProps.isVisible: []
*/
export default {
  name: 'asideMenuItem',
  props: {
    itemProps: {
      type: Object,
      default: () => {},
      requared: true,
    },
  },
  computed: {
    bubble() {
      if (this.itemProps.bubble > 99) return '99+';
      return this.itemProps.bubble || null;
    },
    isDisabled() {
      return this.itemProps.isDisabled || false;
    },
    isVisible() {
      return this.itemProps.isVisible !== false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
