<template>
  <up-card>
    <div class="photo__wrapper">
      <div
        v-if="photo"
        class="photo-item"
      >
        <img
          :src="photo"
          alt=""
        >
      </div>
      <div
        v-else
        :class="photoAlter"
        class="photo-item alter-photo"
      >
        <div>
          {{ firstLetter }}
        </div>
      </div>
    </div>
    <div class="data__wrapper">
      <p class="primary-data">{{ name }}</p>
      <p class="secondary-data mb-1">{{ visiblePosition }}</p>
      <p class="secondary-data">{{ company }}</p>
    </div>
    <template v-slot:stretch-link>
      <router-link
        :to="link"
        class="stretch-link"
      />
    </template>
  </up-card>
</template>

<script>
export default {
  name: 'ParticipantCard',
  props: {
    id: {
      requared: true,
    },
    photo: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    company: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  computed: {
    visiblePosition() {
      return `${this.position.substring(0, 42)}${this.position.length > 42 ? '...' : ''}`;
    },
    link() {
      return `/participant/profile/${this.id}`;
    },
    photoAlter() {
      return ['rose', 'orange', 'blue', 'purpure', 'lilac', 'green'][Math.floor(Math.random() * 6)];
    },
    firstLetter() {
      // eslint-disable-next-line no-unused-vars
      const [name, lastname, ...rest] = this.name.toUpperCase().split(' ');
      return `${name[0]}${lastname[0]}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.photo__wrapper {
  .photo-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 65px;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    // temp style
    // background: #0D0D26;
    border: 1px solid #F6F6FA;
    &.alter-photo {
      font-size: 16px;
      font-weight: 500;
      color: #FEFEFE;
    }
    &.rose {
      background: #FF7777;
    }
    &.orange {
      background: #FFAA77;
    }
    &.blue {
      background: #77AAFF;
    }
    &.purpure {
      background: #FF77DD;
    }
    &.lilac {
      background: #8850CC;
    }
    &.green {
      background: #40BB40;
    }

    & > img {
      max-width: 100%;
      // max-height: 100%;
    }
  }
}
.data__wrapper {
  text-align: center;
  font-size: 12px;
  p {
    margin: 0;
    line-height: 1.3em;
    &.primary-data {
      font-weight: bold;
      color: #0D0D26;
    }
    &.secondary-data {
      color: #AFB0B6;
    }
  }
}
.stretch-link {
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
</style>
