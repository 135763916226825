<template>
  <div class="upCard">
    <div class="upCard__inner">
      <slot />
    </div>
    <slot name="stretch-link" />
  </div>
</template>

<script>
export default {
  name: 'upCard',
};
</script>

<style lang="scss">
  .upCard {
    position: relative;
    height: 100%;
    padding: 10px;
    background: #fefefe;
    border-radius: 20px;
    overflow: hidden;

    &-sm {
      border-radius: 10px;
    }

    &.profile-card {
      width: 100%;
    }

    .upCard__inner {
      display: flex;
      height: 100%;
      flex-direction: column;
      gap: 15px;
    }
    .stretch-link {
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
</style>
