<template>
  <div class="bottom-update__wrapper">
    <div class="bottom-update">
      <p>{{ $t('system.update_info') }}</p>
      <div class="upd-btn-row">
        <up-button
          class="up-btn-update btn-success"
          @click="handleUpdatePwa"
        >
        {{ $t('system.update') }} <i class="exu-refresh-cw-03"/>
        </up-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bottomPwaUpdate',
  props: {
    locale: {
      type: String,
      default: 'ru',
    },
    updatePwaExist: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleUpdatePwa() {
      this.$emit('update-pwa', !this.updatePWAExist);
    },
    handleUpdatePwa_cancel() {
      this.$emit('denide-update-pwa', !this.updatePWAExist);
    },
  },
};
</script>
<style lang="scss" scoped>
.bottom-update {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 10px;

  p {
    margin: 0;
  }

  .upd-btn-row {
    display: flex;
    gap: 12px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    background: #fefefe;
    border-top: 2px solid #F6F6FA;
    box-shadow: 0 -20px 20px rgba(0, 0, 0, .25);
  }

  @media (min-width: 380px) {
    & {
      justify-content: space-around;
    }
  }
}
.up-btn-update {
  font-size: 1.2rem;
  padding: 14px;

  &.btn-success {
    border-color: #D76535;
    color: #D76535;
  }
}
</style>
