import api from '@/components/mixins/baseApi';

export default {
  mixins: [api],
  methods: {
    // eslint-disable-next-line no-unused-vars
    getSection(id) {
      return this.baseAxios({
        method: 'get',
        // url: `${this.$store.getters.apiProgramsPath}/20240227`,
        url: `${this.$store.getters.apiBasePath}/section/${id}`,
      })
        .then((data) => data.results[0] || {})
        .catch((error) => Promise.reject(error));
    },
  },
};
