/* eslint-disable import/no-extraneous-dependencies */
import { createI18n } from 'vue-i18n';
import ru from './locales/ru.json';
import en from './locales/en.json';

function loadLocaleMessages() {
  const locales = [{ ru }, { en }];
  const messages = {};
  locales.forEach((lang) => {
    const key = Object.keys(lang);
    messages[key] = lang[key];
  });
  return messages;
}
export default createI18n({
  locale: localStorage.getItem('__exup__locale') || 'ru',
  fallbackLocale: 'ru',
  legacy: false,
  messages: loadLocaleMessages(),
});
