import api from '@/components/mixins/baseApi';

export default {
  mixins: [api],
  methods: {
    getParticipantList(page = null, search = null) {
      const urlPage = page ? `/${page}` : '';
      const urlSearch = search ? `?search=${search}` : '';
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/participant/page${urlPage}${urlSearch}`,
      })
        .then((data) => data)
        .catch((error) => error);
    },
    getParticipantPersone(id) {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/participant/${id}`,
      })
        .then((data) => data.results)
        .catch((error) => error);
    },
  },
};
