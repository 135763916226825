<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div
    v-if="popupState"
    class="popup__wrapper"
    @click="popupState = false"
  >
    <div class="popup">
      <div class="popup__inner">
        <div class="popup--line">
          <div class="popup--title"></div>
          <button
            class="popup-btn popup-btn-close"
            @click="popupState = false"
          >
          ×
          </button>
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PopupCard',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    popupState: {
      get() {
        return this.isOpen;
      },
      set() {
        this.$emit('show', false);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.popup {
  background: #f0f0f0;
  border-radius: 16px;
  padding: 5px 16px 16px;
  width: 100%;
  max-width: 450px;

  &__wrapper {
    position: fixed;
    z-index: 1100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    background: rgba(0,0,0,.25);
  }
  &__inner {
    display: flex;
    flex-direction: column;
    & > .popup--line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .popup--title {
    flex: 1 1 100%;
  }
  .popup-btn {
    flex: 0 0 auto;
    border: none;
    background: transparent;
    padding: 4px;
    font-size: 24px;
    line-height: 16px;
    &.popup-btn-close {
      color: #95969D;
    }
  }
}
</style>
