<template>
  <div class="participants-list__wrapper">
    <up-input-filter
      @onSearch="(e) => startSearch(e)"
    />
    <div class="participants-list">
      <div class="row">
        <div
          v-for="participant in participantsList"
          :key="participant.id"
          class="col-6 col-md-4"
        >
          <participant-card
            :id="participant.id"
            :photo="participant.photo"
            :name="participant.name"
            :company="participant.company"
            :position="participant.position"
            :description="participant.description"
          />
        </div>
      </div>
      <pre-loader v-if="isLoading"/>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import participants from '@/components/mixins/methodsParticipants';
import banners from '@/components/mixins/methodsBanners';

export default {
  name: 'ListParticipants',
  props: ['launchDownload', 'locale'],
  data() {
    return {
      participantsList: [],
      isLoading: true,
      curPage: 1,
      countPage: null,
      searchInput: null,
    };
  },
  mixins: [participants, banners],
  watch: {
    // TODO пересекается с загрузкой спикеров и партнёров
    launchDownload() {
      if (!this.isLoading && this.launchDownload) {
        this.curPage += 1;
        this.getPage();
      }
    },
    locale() {
      this.$store.commit('setPageTitle', this.$t('participantList.header_title'));
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.$t('participantList.header_title'));
    Promise.all([
      this.getBanner('participant', this.$store.getters.getBannerParticipantTs),
    ])
      .then(([
        banner,
      ]) => {
        this.isLoading = false;
        if (banner && Object.keys(banner).length) {
          this.$emit('showBanner', banner);
          const nowMilis = DateTime.now().toMillis();
          localStorage.setItem('bannerParticipantTs', nowMilis);
          this.$store.commit('setBannerParticipantTs', nowMilis);
        }

        this.getPage();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });
  },
  methods: {
    // getPage(page = null) {
    //   return this.getParticipantList(page);
    // },
    getPage() {
      this.isLoading = true;
      if (this.countPage === null || this.curPage <= this.countPage) {
        this.getParticipantList(this.curPage, this.searchInput)
          .then((data) => {
            this.isLoading = false;
            this.$emit('turnOff', {
              stateDownload: false,
              stateCheckInbox: false,
            });
            this.participantsList = [...this.participantsList, ...data.results];
            this.countPage = data.num_pages;
            this.curPage = data.page;
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.isLoading = false;
      }
    },
    startSearch(data) {
      this.curPage = 1;
      this.participantsList = [];
      this.searchInput = data;
      this.getPage();
    },
  },
};
</script>

<style lang="scss">
.participants-list {
  padding: 5px 20px 20px;

  &__wrapper {
    position: relative;
    display: flex;
    min-height: 100%;
    flex-direction: column;
    gap: 0px;
  }

  & > .row {
    margin: 0 -10px;
    row-gap: 20px;
    & > .col {
      padding: 0 10px;
    }
  }
}
</style>
