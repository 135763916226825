import api from '@/components/mixins/baseApi';

export default {
  mixins: [api],
  methods: {
    getNotificationList(page = 1) {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/notification/page/${page}`,
      })
        .then((data) => data || { results: [] })
        .catch(() => false);
    },
    getNotificationById(id) {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/notification/${id}`,
      })
        .then((data) => data || {})
        .catch(() => false);
    },
    notificationVeiwed(id) {
      return this.baseAxios({
        method: 'put',
        url: `${this.$store.getters.apiBasePath}/notification/${id}`,
      })
        .then((data) => data || {})
        .catch(() => false);
    },
  },
};
