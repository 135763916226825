<template>
  <div class="programs-list__wrapper">
    <div class="program-filter-line">
      <nav-tab
        :tabs="optionsDateList"
        :cur-url="selectedDate"
        @change-day="updateProgramList"
      />
      <drop-list
        :options="optionsHall"
        :selected="selectedHall"
        :default-name="$t('programList.default_hall')"
        @update="handleUpdateSelected"
      />
    </div>
    <div
      v-if="!isLoading"
      class="programs-list"
    >
      <section-card
        v-for="section in programList"
          :key="section.id"
          :section="section"
      />
    </div>
      <pre-loader v-if="isLoading"/>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import programs from '@/components/mixins/methodsPrograms';
import banners from '@/components/mixins/methodsBanners';
import timeCombaine from '@/components/mixins/timeCombaine';

export default {
  name: 'ListPrograms',
  props: ['launchDownload', 'locale', 'date'],
  data() {
    return {
      programList: [],
      DateList: [],
      hallList: [],
      isLoading: false,
      selectedDate: null,
      selectedHall: null,
      countPage: null,
    };
  },
  mixins: [programs, banners, timeCombaine],
  computed: {
    optionsHall() {
      if (this.hallList.length > 0) {
        return this.hallList;
      }
      return [];
    },
    optionsDateList: {
      get() {
        return [...this.DateList].map((itemDay) => {
          // eslint-disable-next-line no-unused-vars
          const [trash, year, month, day, ...rest] = itemDay.match(/^(.{4})(.{2})(.{2})$/);
          return {
            name: DateTime.fromObject({ year, month, day }).setLocale(this.$store.getters.getLang).toFormat('d MMMM'),
            url: itemDay,
          };
        });
      },
      set(_dateList) {
        this.DateList = [..._dateList];
      },
    },
  },
  watch: {
    locale() {
      this.$store.commit('setPageTitle', this.$t('programList.header_title'));
      this.$router.go();
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.$t('programList.header_title'));
    this.$emit('loading', true);
    Promise.all([
      this.getBanner('program', this.$store.getters.getBannerProgramTs),
      this.getAllDate(),
      this.getHallList(),
    ])
      .then(([
        banner,
        allDate,
        allHalls,
      ]) => {
        if (banner && Object.keys(banner).length) {
          this.$emit('showBanner', banner);
          const nowMilis = DateTime.now().toMillis();
          localStorage.setItem('bannerProgramTs', nowMilis);
          this.$store.commit('setBannerProgramTs', nowMilis);
        }

        this.optionsDateList = allDate;
        this.setHallList(allHalls);
        this.selectedDate = this.date || allDate[0];
        this.getPage();
      })
      .finally(() => {
        this.$emit('loading', false);
      })
      .catch((error) => {
        console.error('List program error:: ', error);
      });
  },
  methods: {
    setHallList(hallList_) {
      this.hallList = [...hallList_];
    },
    getPage() {
      this.isLoading = true;
      this.getProgramList(this.selectedDate, this.selectedHall)
        .then((data) => {
          this.isLoading = false;
          this.$emit('turnOff', {
            stateDownload: false,
            stateCheckInbox: false,
          });
          this.programList = [...data.results];
          this.countPage = data.num_pages;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    sessionDate(i) {
      return this.dateEvent(this.timeStart(this.programList[i].start_ts));
    },
    updateProgramList(newDate) {
      this.selectedDate = newDate;
      this.getPage();
    },
    handleUpdateSelected(selected) {
      this.selectedHall = selected;
      this.getPage();
    },
  },
};
</script>

<style lang="scss">
.programs-list {
  &__wrapper {
    position: relative;
    display: flex;
    min-height: 100%;
    flex-direction: column;
    gap: 12px;

    .program-filter-line {
      display: block;
      padding: 0;
      position: sticky;
      top: 0;
      z-index: 100;
      border-bottom: 2px solid #F6F6FA;
    }
  }

  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 0 12px 12px;
}
</style>
