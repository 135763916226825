<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <div class="input__wrapper">
    <label
      v-if="labelText"
      :for="id"
    >
      текст
    </label>
    <input
      :id="id"
      v-model="value"
      class="up-form-control"
    >
  </div>
</template>
<script>
export default {
  name: 'upInput',
  props: {
    propID: null,
    labelText: null,
    propValue: null,
  },
  computed: {
    id() {
      return this.propID || `input-${Date.now()}`;
    },
    value: {
      get() {
        return this.propValue;
      },
      set(data) {
        this.$emit('onInput', data);
      },
    },
  },
};
</script>
<style lang="scss">
.input__wrapper {
  display: flex;
  width: 100%;

  input.up-form-control {
    height: 52px;
    flex: 1 1 100%;
    color: #0D0D26;
    background: #FFFFFF;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    padding: 0 12px;

    &:active, &:focus {
      outline: none;
    }
  }
}
</style>
