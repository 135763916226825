import api from '@/components/mixins/baseApi';

export default {
  mixins: [api],
  methods: {
    getPage(alias) {
      return this.baseAxios({
        method: 'get',
        url: `${this.$store.getters.apiBasePath}/static-page/alias/${alias}`,
      })
        .then((data) => data.results)
        .catch((error) => error);
    },
  },
};
